.top_right_btns[data-v-4341e2c3] {
  float: right;
  margin-right: 15px;
}
.top_right_btns i[data-v-4341e2c3] {
  margin: 0 5px;
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
  font-size: 18px;
  line-height: 24px;
}
.el-select[data-v-4341e2c3] {
  margin: 0px;
}
.checkRate[data-v-4341e2c3] {
  cursor: pointer;
}
.checkRate[data-v-4341e2c3]:hover {
  color: var(--themeColor, #17a2b8);
}
[data-v-4341e2c3] .el-radio__label {
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}